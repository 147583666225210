import { Link } from "react-scroll";
import logo from "../../assets/images/otros/logo-white.png";
import {useEffect, useState} from "react";

const Header = () => {
  const [menuExpanded, setMenuExpanded] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setMenuExpanded(false);
    });
  }, []);

  return (
    <header id="navigation" className="navigation" style={{ background: '#3a434c' }}>
      <div className="container">
        <div className="navbar-header w-100">
          <nav className="navbar navbar-expand-lg navbar-dark px-0">
            <img
              src={logo}
              alt="logo"
              height={50} 
              style={{ 
                borderRadius: 10,
                transform: 'scale(2) translateX(20px) translateY(7px)',
              }}
            ></img>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#menuExpanded"
              aria-controls="menuExpanded"
              aria-expanded="false"
              //aria-label="Toggle navigation"
              onClick={() => setMenuExpanded(x => !x)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className={`collapse navbar-collapse ${menuExpanded ? 'show' : ''}`} id="menuExpanded">
              <ul className="navbar-nav navigation-menu ml-auto">
                <li className="nav-item">
                  <Link  className="nav-link" to="slider" spy={true} smooth={true}>
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link  className="nav-link" to="about" spy={true} smooth={true}>
                    About us
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="services" spy={true} smooth={true}>
                    Servicios
                  </Link>
                </li> */}
                {/* <li className="nav-item">
                  <Link className="nav-link" to="clients" spy={true} smooth={true}>
                    Clientes
                  </Link>
                </li> */}
                <li  className="nav-item">
                  <Link  className="nav-link" to="contact-us" spy={true} smooth={true}>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
