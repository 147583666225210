import Footer from './Footer';
import Header from './Header';
import Preloder from './Preloader';

const Layout = ({ children }) => {
  return (
    <>
      <Preloder />
      <Header />
      <>{children}</>
      <Footer />
    </>
  )
}

export default Layout;