import Aos from "aos"
import { useEffect, useState } from "react"

const Preloder = () => {
   const [fadeOut, setFadeOut] = useState(false)
   const [hidden, setHidden] = useState(false)

   useEffect(() => {
      setTimeout(() => {
         Aos.init();
         setFadeOut(true)
      }, 1000);

      setTimeout(() => {
         setHidden(true)
      }, 1050);
   }, [])

   if (hidden) return null

   return (
      <div className={`preloader ${fadeOut ? 'animate__animated animate__fadeOut animate__faster' : ''}`}>
         <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
         </div>
      </div>
   )
}

export default Preloder