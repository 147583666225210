const Footer = () => (
   <footer id="footer" className="bg-one" style={{ background: '#3a434c' }}>
      <div className="container">
         <div className="row" data-aos="fade-up" data-aos-duration={500}>
            <div className="col-lg-12">
               <div className="social-icon">
                  {/*<ul className="list-inline">*/}
                  {/*   <li className="list-inline-item"><a href="https://facebook.com/themefisher"><i className="tf-ion-social-facebook"></i></a></li>*/}
                  {/*   <li className="list-inline-item"><a href="https://twitter.com/themefisher"><i className="tf-ion-social-twitter"></i></a></li>*/}
                  {/*   <li className="list-inline-item"><a href="https://www.youtube.com/channel/UCx9qVW8VF0LmTi4OF2F8YdA"><i className="tf-ion-social-youtube"></i></a></li>*/}
                  {/*   <li className="list-inline-item"><a href="https://dribbble.com/themefisher"><i className="tf-ion-social-dribbble-outline"></i></a></li>*/}
                  {/*   <li className="list-inline-item"><a href="https://pinterest.com/themefisher"><i className="tf-ion-social-pinterest-outline"></i></a></li>*/}
                  {/*</ul>*/}
               </div>

               <div className="copyright text-center">
                  <p className="mt-3">Copyright
                     &copy; {new Date().getFullYear()}. Todos los Derechos Reservados. Gio Textil S.A.C.
                  </p>
               </div>
            </div>
         </div>
      </div>
   </footer>
)

export default Footer