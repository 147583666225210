import aboutBusinessMan from '../../assets/images/about/about-business-man.jpg'

const About = () => (
   <>
      <section id="about" className="bg-one about section" style={{ background: '#4e565e' }}>
         <div className="container">
            <div className="row">
               <div className="col-12">
                  <div className="title text-center" data-aos="zoom-in" data-aos-duration={1500}>
                     <h2>About <span className="color">Us</span></h2>
                     <div className="border"></div>
                  </div>
               </div>
               <div
               className="col-12 text-center"                  
               >
                  <p style={{ color: '#fff', fontSize: 19 }}>
                     <b style={{ color: "#57cbcc", fontSize: 23, }}>Gio Textil </b> is a woman owned apparel manufacturing co. based in Lima-Peru, with more than 15 years’ experience in the field.</p>
                  <p style={{ color: '#fff', marginBottom: 40, fontSize: 19 }}
                  >Currently ranked top 20 exporting textile company in Peru and working together with different clients worldwide.
                  </p>

               </div>

               <div className="col-md-4 text-center" data-aos="fade-up" data-aos-duration={500}>
                  <div className="block rounded">
                     {/* <div className="icon-box">
                        <i className="tf-tools"></i>
                     </div> */}
                     <div className="content text-center">
                        <h3 className="ddd">Service</h3>
                        <p>Graphic T-shirts, fleece hoodies, shorts, thermals. Woven shirts, plain, listed, squared, dobby and jacquard fabrics. We develop exclusive designs for all our clients.</p>
                     </div>
                  </div>
               </div>

               <div className="col-md-4 text-center" data-aos="fade-up" data-aos-duration={500} data-aos-delay={250}>
                  <div className="block rounded">
                     {/* <div className="icon-box">
                        <i className="tf-strategy"></i>
                     </div> */}
                     <div className="content text-center">
                        <h3>Team</h3>
                        <p>Professionals with more than 15 years’ experience in the industry. We pursue excellence in every order shipped.</p>
                     </div>
                  </div>
               </div>

               <div className="col-md-4 text-center" data-aos="fade-up" data-aos-duration={500} data-aos-delay={500}>
                  <div className="block kill-margin-bottom rounded">
                     {/* <div className="icon-box">
                        <i className="tf-anchor2"></i>
                     </div> */}
                     <div className="content text-center">
                        <h3>Partnership</h3>
                        <p>We work side by side with our clients, as partners, so we can grow together.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      {/* <section className="section about-2 padding-0 bg-dark">
         <div className="container-fluid p-0">
            <div className="row no-gutters align-items-center">
               <div className="col-lg-6">
                  <img className="img-fluid" src={aboutBusinessMan} data-aos="fade-right" data-aos-duration={500} alt="" />
               </div>
               <div className="col-lg-6">
                  <div className="content-block" data-aos="fade-left" data-aos-duration={500} data-aos-delay={250}>
                     <h2>No somos solo proveedores de fabricantes de ropa, somos sus socios comerciales.
                     </h2>
                     <p>
                        Gio Textil is a woman owned apparel manufacturing co. based in Lima-Peru, with more than 10 years’ experience in the field.
                     </p>
                     <p>
                        Currently ranked top 15 exporting textile company in Peru and working together with different clients all around the US.
                     </p>
                     <div className="row">
                        <div className="col-md-6">
                           <div className="media">
                              <div className="pull-left">
                                 <i className="tf-circle-compass"></i>
                              </div>
                              <div className="media-body">
                                 <h4 className="media-heading">SEO Optimized</h4>
                                 <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Possimus consectetur dolores repellendus
                                    eos saepe</p>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-6">
                           <div className="media">
                              <div className="pull-left">
                                 <i className="tf-hotairballoon"></i>
                              </div>
                              <div className="media-body">
                                 <h4 className="media-heading">Easy Customization</h4>
                                 <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Possimus consectetur dolores repellendus
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section> */}
   </>
)

export default About