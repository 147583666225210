import {
   GoogleMap,
   LoadScript,
   Marker,
} from '@react-google-maps/api';

const center = {
   lat: -12.01936911895996,
   lng: -76.99496396058005
};

const containerStyle = {
   width: '100%',
   height: '400px'
};

const ContactUs = () => {
   return (
      <section id="contact-us" className="contact-us section-bg" style={{ background: '#3a434c' }}>
         <div className="container">
            <div className="row">

               <div className="col-12">
                  <div className="title text-center" data-aos="zoom-in" data-aos-duration={500}>
                     <h2>Contact <span className="color">Us</span></h2>
                     <div className="border"></div>
                  </div>
               </div>

               <div className="contact-info col-lg-6" data-aos="fade-up" data-aos-duration={500}>
                  {/* <h3>Detalles de Contacto</h3> */}
                  {/*<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam, vero, provident, eum eligendi blanditiis ex*/}
                  {/*   explicabo vitae nostrum facilis asperiores dolorem illo officiis ratione vel fugiat dicta laboriosam labore*/}
                  {/*   adipisci.</p>*/}
                  {/* <p></p> */}
                  <div className="contact-details">
                     <div className="con-info clearfix" style={{ color: '#fff' }}>
                        <i className="tf-map-pin"></i>
                        <span>Av. Santuario 1130, Zárate, San Juan de Lurigancho</span> <br/>
                        <i className="tf-map-pin"></i>
                        <span>Av. Tapiceros 280, Ate </span>
                     </div>

                     <div className="con-info clearfix" style={{ color: '#fff' }}>
                        <i className="tf-ion-ios-telephone-outline"></i>
                        <span>Phone Number: +51 982330750</span>
                     </div>

                     {/*<div className="con-info clearfix">*/}
                     {/*   <i className="tf-ion-iphone"></i>*/}
                     {/*   <span>Fax: +880-31-000-000</span>*/}
                     {/*</div>*/}

                     <div className="con-info clearfix" style={{ color: '#fff' }}>
                        <i className="tf-ion-ios-email-outline"></i>
                        <span>Email: ventas@giotextil.com</span>
                     </div>
                  </div>
               </div>

               <div className="contact-form col-lg-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-duration={500} data-aos-delay={300}>
                  <form id="contact-form" method="post" action="sendmail.php">
                     <div className="form-group">
                        <input type="text" placeholder="Name" className="form-control" name="name" id="name" />
                     </div>

                     <div className="form-group">
                        <input type="email" placeholder="Email" className="form-control" name="email" id="email" />
                     </div>

                     <div className="form-group">
                        <input type="text" placeholder="Subject" className="form-control" name="subject" id="subject" />
                     </div>

                     <div className="form-group">
                        <textarea rows="6" placeholder="Message" className="form-control" name="message" id="message"></textarea>
                     </div>

                     <div id="cf-submit">
                        <input type="submit" id="contact-submit" className="btn btn-transparent" value="Send" />
                     </div>
                  </form>
               </div>
            </div>
         </div>

         <LoadScript
           googleMapsApiKey={'AIzaSyAkzkjp0Try-gl45r5siMHDzitBBAyZ6Gk'}
         >
            <GoogleMap
               mapContainerStyle={containerStyle}
               center={center}
               zoom={15}
            >
               <Marker
                  position={center}
               />
            </GoogleMap>
         </LoadScript>
      </section>
   )
}

export default ContactUs