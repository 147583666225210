import About from "../components/home/About"
import CallToAction from "../components/home/CallToAction"
import ContactUs from "../components/home/ContactUs"
import Counter from "../components/home/Counter"
import Services from "../components/home/Services"
import Slider from "../components/home/Slider"
import Clients from "../components/home/Clients"
import wsapp from '../assets/images/otros/wsapp.png';

const Home = () => {
   return (
      <>
         <Slider />
         <About />
         {/* <CallToAction /> */}
         {/* <Services /> */}
         {/* <Counter /> */}
         {/* <Clients /> */}
         <ContactUs />
         <div style={{
            display: 'block',
            width: '70px',
            height: '70px',
            color: '#fff',
            position: 'fixed',
            right: 20,
            bottom: 40,
            borderRadius: '50%',
            lineHeight: '80px',
            textAlign: 'center',
            zIndex: 999,

         }}>
            <a href="https://api.whatsapp.com/send?phone=51982330750" target="_blank">
               <img src={wsapp} alt="" loading="lazy" />
            </a>
         </div>
      </>
   )
}

export default Home