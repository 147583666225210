import { Carousel } from "react-responsive-carousel";
import slider1 from "../../assets/images/otros/IMG_5364.jpg";
import slider2 from "../../assets/images/otros/IMG_5421.jpg";
import slider3 from "../../assets/images/otros/IMG_5492.jpg";
import slider4 from "../../assets/images/otros/IMG_5894.jpg";
import slider5 from "../../assets/images/otros/IMG_5530.jpg";
import slider6 from "../../assets/images/otros/IMG_5573.jpg";
import slider7 from "../../assets/images/otros/IMG_5610.jpg";
import slider8 from "../../assets/images/otros/IMG_5929_H.jpg";
import slider9 from "../../assets/images/otros/IMG_5654.jpg";
import slider10 from "../../assets/images/otros/IMG_6024.jpg";
import slider11 from "../../assets/images/otros/IMG_6028.jpg";
// import slider12 from "../../assets/images/otros/IMG_5727.jpg";
// import slider13 from "../../assets/images/otros/IMG_5767.jpg";
// import slider14 from "../../assets/images/otros/IMG_5776.jpg";
import slider15 from "../../assets/images/otros/IMG_5805.jpg";
import slider16 from "../../assets/images/otros/IMG_5860.jpg";
import slider17 from "../../assets/images/otros/IMG_5964.jpg";

const Slider = () => (
  <section id="slider" className="overlay">
    <div style={{
      position: "absolute",
      textAlign: 'center',
      left: '50%',
      top: '50%',
      zIndex: "10",

    }}>
      <p style={{
        color: 'white',        
        fontSize: '4vw',        

      }}> We are not just clothing manufacturers suppliers,
         we are your <b style={{ color:"#57cbcc"}}> business partner</b>.</p>

    </div>
    <Carousel autoPlay={true} interval={5000} swipeable={true} emulateTouch={true} infiniteLoop={true} showThumbs={false} showStatus={false}>
      <div>
        <img src={slider1} alt="Slider 1" />
      </div>
      <div>
        <img src={slider2} alt="Slider 2" />
      </div>
      <div>
        <img src={slider3} alt="Slider 3" />
      </div>
      {/* <div>
        <img src={slider4} alt="Slider 4" />
      </div> */}
      <div>
        <img src={slider5} alt="Slider 5" />
      </div>
      <div>
        <img src={slider6} alt="Slider 6" />
      </div>
      <div>
        <img src={slider7} alt="Slider 7" />
      </div>
      <div>
        <img src={slider8} alt="Slider 8" />
      </div>
      <div>
        <img src={slider9} alt="Slider 9" />
      </div>
      <div>
        <img src={slider10} alt="Slider 10" />
      </div>
      <div>
        <img src={slider11} alt="Slider 11" />
      </div>
      {/* <div>
        <img src={slider12} alt="Slider 12" />
      </div>
      <div>
        <img src={slider13} alt="Slider 13" />
      </div>
      <div>
        <img src={slider14} alt="Slider 14" />
      </div> */}
      {/* <div>
        <img src={slider15} alt="Slider 15" />
      </div> */}
      <div>
        <img src={slider16} alt="Slider 16" />
      </div>
      <div>
        <img src={slider17} alt="Slider 17" />
      </div>
    </Carousel>
  </section>
);

export default Slider;
